import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import Header from './components/Header';
import Footer from './components/Footer';
import AnnouncementBar from './components/AnnouncementBar';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Architectural from './pages/Architectural';
import Commercial from './pages/Commercial';
import NotFound from './pages/NotFound';
import FAQ from './pages/FAQ';
import './App.css';

function AnimatedRoutes({ announcementVisible }) {
  const location = useLocation();
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={location.key}
        timeout={300}
        classNames="page"
        unmountOnExit
      >
        <main 
          className="main" 
          style={{ marginTop: announcementVisible ? '80px' : '70px' }}
        >
          <Routes location={location}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/architectural" element={<Architectural />} />
            <Route path="/commercial" element={<Commercial />} />
            <Route path="/faqs" element={<FAQ />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
      </CSSTransition>
    </TransitionGroup>
  );
}

function App() {
  const [announcementVisible, setAnnouncementVisible] = useState(true);

  const handleAnnouncementClose = () => {
    setAnnouncementVisible(false);
  };

  return (
    <FluentProvider theme={webLightTheme}>
      <Router>
        <div className="container">
          <AnnouncementBar 
            onClose={handleAnnouncementClose} 
            visible={announcementVisible}
          />
          <Header announcementVisible={announcementVisible} />
          <AnimatedRoutes announcementVisible={announcementVisible} />
          <Footer />
        </div>
      </Router>
    </FluentProvider>
  );
}

export default App;