import React from 'react';
import styles from './Footer.module.css';
import { FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className={styles.footer} id="footer" role="contentinfo" tabIndex="0">
      <div className={styles.container} role="presentation">
        <div className={styles.column} role="group" aria-labelledby="company-info">
          <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Company Logo" className={styles.logo} />
          <p className={styles.address}>
            <a href="https://www.google.com/maps?q=15810+114+Ave+NW,+Edmonton,+AB+T5M+2Z4" target="_blank" rel="noopener noreferrer" tabIndex="0">
              15810 114 Ave NW, Edmonton, AB T5M 2Z4
            </a>
            <br />
            <a href="mailto:hello@example.com" tabIndex="0">hello@abcrailing.com</a>
            <br />
            <a href="tel:+17804390801" tabIndex="0">+1 780 439 0801</a>
          </p>
        </div>
        <div className={styles.column} role="group" aria-labelledby="follow-us">
          <h2 id="follow-us" className={styles.followUs}>Follow Us</h2>
          <div className={styles.socialLinks}>
            <a href="https://www.instagram.com/southsidemanufacturing/" target="_blank" rel="noopener noreferrer" tabIndex="0">
              <FaInstagram size={24} />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.bottom} role="contentinfo">
        <p className={styles.bottomText}>
          &copy; {new Date().getFullYear()} ABC Railing. All rights reserved. |
          <a href="/privacy" className={styles.link} tabIndex="0"> Privacy Policy</a> |
          <a href="/terms" className={styles.link} tabIndex="0"> Terms of Service</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;