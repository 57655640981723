import React, { useState, useEffect } from 'react';
import styles from './AnnouncementBar.module.css';

const AnnouncementBar = ({ onClose, visible }) => {
  const [isVisible, setIsVisible] = useState(visible);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const announcementDismissed = sessionStorage.getItem('announcementDismissed');
    if (announcementDismissed === 'true') {
      setIsVisible(false);
      onClose();
    }
  }, [onClose]);

  const handleClose = () => {
    setIsVisible(false);
    sessionStorage.setItem('announcementDismissed', 'true');
    onClose();
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!isVisible) return null;

  return (
    <>
      <div className={styles.announcementBar}>
        <div className={styles.content}>
          <span>
            South Side Manufacturing is now <strong>ABC Railing</strong>!
            <button
              onClick={handleOpenModal}
              className={styles.learnMoreBtn}
            >
              Learn More
            </button>
          </span>
          <button
            onClick={handleClose}
            className={styles.closeBtn}
            aria-label="Close announcement"
          >
            &times;
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button onClick={handleCloseModal} className={styles.closeModalIcon} aria-label="Close modal">
              &times;
            </button>
            <h2>South Side Manufacturing is Now ABC Railing!</h2>
            <div className={styles.logos}>
              <img src="/Ssmfg-Logo.png" alt="South Side Manufacturing Logo" className={styles.logo} />
              <span>is now</span>
              <img src="/logo.svg" alt="ABC Railing Logo" className={styles.logo} />
            </div>
            <p>
              We’re excited to announce our transformation! As of January 30, 2025, we are proudly operating as ABC Railing, formerly South Side Manufacturing Ltd.
              This change reflects our commitment to Edmonton’s building community and better meeting the unique needs of our Architectural, Builder, and Commercial clients.
            </p>
            <p>
              By listening to our customers, we’ve refined how we serve you — delivering high-quality products with faster lead times.
              Proudly serving Edmonton and beyond, we invite you to explore how ABC Railing can support your next project.
            </p>
            <p>
              Visit us at <a href="https://www.abcrailing.com" tabIndex="0">abcrailing.com</a> or contact us at <a href="tel:+17804390801" tabIndex="0">+1 780 439 0801</a> or <a href="mailto:hello@abcrailing.com">hello@abcrailing.com</a> for all your enquiries.
            </p>
            <p>
              *Please take note of our updated email addresses.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AnnouncementBar;