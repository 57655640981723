import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './FAQ.module.css';

function FAQ() {
  return (
    <div className={styles.faqContainer}>
      <Helmet>
        <title>Internal FAQs - ABC Railing</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <h1 className={styles.title}>Frequently Asked Questions</h1>
      <div className={styles.content}>
        <div className={styles.faqList}>
          <div className={styles.faqItem}>
            <h3 className={styles.question}>Has the business location and key contacts changed?</h3>
            <p className={styles.answer}>No. The business location remains as 15810-114 Ave NW, and all main contacts will be reaching out to you and your teams to update our contact information over the next several weeks. Our phone number remains (780) 439-0801 and all legacy emails will continue to work until all contacts have been updated.</p>
          </div>

          <div className={styles.faqItem}>
            <h3 className={styles.question}>Who should purchase orders be issued to?</h3>
            <p className={styles.answer}>All purchase orders should be issued to "South Side Manufacturing o/a ABC Railing." Existing purchase orders can remain as South Side Manufacturing and will be processed accordingly.</p>
          </div>

          <div className={styles.faqItem}>
            <h3 className={styles.question}>How should invoices be processed?</h3>
            <p className={styles.answer}>All invoices from our company will now be issued under "South Side Manufacturing o/a ABC Railing." Please ensure payments are made accordingly to avoid processing delays. Existing invoices can remain as South Side Manufacturing and will be processed accordingly.</p>
          </div>

          <div className={styles.faqItem}>
            <h3 className={styles.question}>What name should be used for contracts and agreements?</h3>
            <p className={styles.answer}>Any contracts, agreements, or official documents should reference "South Side Manufacturing o/a ABC Railing." Existing contracts can remain as South Side Manufacturing and will be fulfilled accordingly.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;