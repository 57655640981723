import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import styles from './Architectural.module.css';
import { createPortal } from 'react-dom';
import imageData from '../components/imageData.js';

const Architectural = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentGallery, setCurrentGallery] = useState(null);

  const interiorImages = imageData.filter(image => image.galleryType === 'interior');
  const exteriorImages = imageData.filter(image => image.galleryType === 'exterior');

  const handleImageNavigation = (direction) => {
    if (!currentGallery || !selectedImage) return;

    const images = currentGallery === 'interior' ? interiorImages : exteriorImages;
    const currentIndex = images.findIndex(img => img.id === selectedImage.id);
    let newIndex;

    if (direction === 'next') {
      newIndex = (currentIndex + 1) % images.length;
    } else {
      newIndex = (currentIndex - 1 + images.length) % images.length;
    }

    setSelectedImage(images[newIndex]);
  };

  const Gallery = ({ title, images, description, galleryType }) => {
    const [touchedImageId, setTouchedImageId] = useState(null);
    const [showAllImages, setShowAllImages] = useState(false);

    const handleTouch = (imageId) => {
      setTouchedImageId((prev) => (prev === imageId ? null : imageId));
    };

    const displayedImages = showAllImages ? images : images.slice(0, 8);

    return (
      <section className={styles.section} aria-labelledby={`${title}-heading`}>
        <h2 id={`${title}-heading`} className={styles.sectionTitle}>{title}</h2>
        <p className={styles.sectionDescription}>{description}</p>

        <div
          className={styles.gallery}
          role="region"
          aria-label={`${title} gallery`}
        >
          {displayedImages.map((image) => (
            <div key={image.id} className={styles.imageContainer}>
              <button
                className={`${styles.imageButton} ${touchedImageId === image.id ? styles.touched : ''}`}
                onClick={() => {
                  setSelectedImage({
                    src: image.src,
                    alt: image.alt,
                    description: image.description,
                  });
                  setCurrentGallery(galleryType);
                }}
                onTouchStart={() => handleTouch(image.id)}
                aria-label={`View larger image of ${image.alt}`}
              >
                <img
                  src={image.thumbnailSrc}
                  alt={image.alt}
                  className={styles.image}
                  loading="lazy"
                />
                <div className={styles.imageOverlay}>
                  <p className={styles.imageDescription}>{image.description}</p>
                </div>
              </button>
            </div>
          ))}
          {images.length > 8 && !showAllImages && (
            <div className={styles.imageContainer}>
              <button
                className={styles.imageButton}
                onClick={() => {
                  setShowAllImages(true);
                }}
                aria-label={`See more ${title}`}
              >
                <p className={styles.seeMore}>See more</p>
              </button>
            </div>
          )}
        </div>
      </section>
    );
  };

  useEffect(() => {
    if (selectedImage) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [selectedImage]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Architectural Solutions | Custom Iron and Aluminum Railings</title>
        <meta name="description" content="Discover our premium architectural solutions featuring custom interior iron work and exterior aluminum installations. View our portfolio and request a consultation." />
        <meta name="keywords" content="aluminum railings, aluminum railing, aluminum railings edmonton, aluminum railing edmonton, iron railings, custom iron railings, custom iron railings edmonton, custom iron railing edmonton, spiral staircase, custom spiral staircase, custom spiral staircase edmonton, aluminum spiral staircase, custom fabrication, custom railings, architectural railings, ornamental railings" />
      </Helmet>

      <header className={styles.enhancedHero}>
        <div className={styles.heroContent}>
          <h1>Architectural Metal Solutions</h1>
          <p>Precision Engineering. Elegant Design.</p>
          <div className={styles.heroSubtext}>
          Explore our custom iron and aluminum railings, stunning custom spiral staircases, aluminum stringers, privacy dividers, and more.
          </div>
        </div>
        <div className={styles.heroGraphic}>
          <svg viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10 90 Q100 20, 190 90"
              fill="none"
              stroke="rgba(0, 0, 0, 0.2)"
              strokeWidth="2"
            />
            <path
              d="M0 80 Q100 10, 200 80"
              fill="none"
              stroke="rgba(0, 0, 0, 0.1)"
              strokeWidth="1"
            />
          </svg>
        </div>
      </header>

      <main className={styles.main}>
        <Gallery
          title="Interior Iron Works"
          images={interiorImages}
          description="Transform your space with our bespoke interior iron installations. Each piece is fully welded for unparalleled strength, meticulously ground for a flawless finish, and brilliantly powder coated in the color of your choice. Our custom iron solutions blend innovative design with superior craftsmanship to create a stunning and durable addition to your interiors. Explore some of our most beloved projects below."
          galleryType="interior"
        />

        <Gallery
          title="Exterior Aluminum Solutions"
          images={exteriorImages}
          description="Elevate your building's exterior with our premium aluminum railings. Custom-built to fit any requirements, our aluminum and glass railings are fully welded for enhanced durability and aesthetic appeal. Choose from a range of premium powder coat colors to perfectly match your vision. Our railings not only enhance the beauty of your property but also provide lasting protection and functionality. Check out some of our finest work below."
          galleryType="exterior"
        />

        <div className={styles.ctaSection}>
          <h2>Ready to Transform Your Space?</h2>
          <button
            onClick={() => navigate('/contact')}
            className={styles.ctaButton}
            aria-label="Contact us for a consultation"
          >
            Request Consultation
          </button>
        </div>
      </main>

      {selectedImage && createPortal(
        <div
          className={styles.modal}
          onClick={() => setSelectedImage(null)}
        >
          <div
            className={styles.modalContent}
            onClick={e => e.stopPropagation()}
          >
            <img
              src={selectedImage.src}
              alt={selectedImage.alt}
              className={styles.modalImage}
            />
            <p className={styles.modalDescription}>
              {selectedImage.description}
            </p>
            <button
              className={`${styles.navButton} ${styles.prevButton}`}
              onClick={(e) => {
                e.stopPropagation();
                handleImageNavigation('prev');
              }}
            >
              ‹
            </button>
            <button
              className={`${styles.navButton} ${styles.nextButton}`}
              onClick={(e) => {
                e.stopPropagation();
                handleImageNavigation('next');
              }}
            >
              ›
            </button>
            <button
              className={styles.closeButton}
              onClick={() => setSelectedImage(null)}
            >
              ×
            </button>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

export default Architectural;