import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import styles from './Commercial.module.css';
import { createPortal } from 'react-dom';
import imageData from '../components/imageData.js';

const Commercial = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);

  const commercialImages = imageData.filter(image => image.galleryType === 'commercial');

  const handleImageNavigation = (direction) => {
    if (!selectedImage) return;

    const currentIndex = commercialImages.findIndex(img => img.id === selectedImage.id);
    let newIndex;

    if (direction === 'next') {
      newIndex = (currentIndex + 1) % commercialImages.length;
    } else {
      newIndex = (currentIndex - 1 + commercialImages.length) % commercialImages.length;
    }

    setSelectedImage(commercialImages[newIndex]);
  };

  const Gallery = ({ title, images, description, galleryType }) => {
    const [touchedImageId, setTouchedImageId] = useState(null);
    const [showAllImages, setShowAllImages] = useState(false);

    const handleTouch = (imageId) => {
      setTouchedImageId((prev) => (prev === imageId ? null : imageId));
    };

    const displayedImages = showAllImages ? images : images.slice(0, 8);

    return (
      <section className={styles.section} aria-labelledby={`${title}-heading`}>
        <h2 id={`${title}-heading`} className={styles.sectionTitle}>{title}</h2>
        <p className={styles.sectionDescription}>{description}</p>
  
        <div
          className={styles.gallery}
          role="region"
          aria-label={`${title} gallery`}
        >
          {displayedImages.map((image) => (
            <div key={image.id} className={styles.imageContainer}>
              <button
                className={`${styles.imageButton} ${touchedImageId === image.id ? styles.touched : ''}`}
                onClick={() => {
                  setSelectedImage({
                    src: image.src,
                    alt: image.alt,
                    description: image.description,
                    id: image.id
                  });
                }}
                onTouchStart={() => handleTouch(image.id)}
                aria-label={`View larger image of ${image.alt}`}
              >
                <img
                  src={image.thumbnailSrc}
                  alt={image.alt}
                  className={styles.image}
                  loading="lazy"
                />
                <div className={styles.imageOverlay}>
                  <p className={styles.imageDescription}>{image.description}</p>
                </div>
              </button>
            </div>
          ))}
          {images.length > 8 && !showAllImages && (
            <div className={styles.imageContainer}>
              <button
                className={styles.imageButton}
                onClick={() => {
                  setShowAllImages(true);
                }}
                aria-label={`See more ${title}`}
              >
                <p className={styles.seeMore}>See more</p>
              </button>
            </div>
          )}
        </div>
      </section>
    );
  };

  useEffect(() => {
    if (selectedImage) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [selectedImage]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Commercial Metal Solutions | Industrial Railings and Fencing</title>
        <meta name="description" content="Explore our comprehensive commercial metal solutions including industrial stairwell railings, multi-family housing fencing, and custom metal installations." />
        <meta name="keywords" content="aluminum fencing, custom fencing, commercial railing, commercial iron railings, stairwell railings, apartment railings, builder railings" />
      </Helmet>

      <header className={styles.enhancedHero}>
        <div className={styles.heroContent}>
          <h1>Commercial Metal Solutions</h1>
          <p>Engineering Excellence. Functional Design.</p>
          <div className={styles.heroSubtext}>
          Explore our comprehensive commercial metal solutions, including durable aluminum and iron fencing, stylish stairwell railings, other interior iron railings, and custom fencing options tailored to your needs.
          </div>
        </div>
        <div className={styles.heroGraphic}>
          <svg viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10 90 Q100 20, 190 90"
              fill="none"
              stroke="rgba(0, 0, 0, 0.2)"
              strokeWidth="2"
            />
            <path
              d="M0 80 Q100 10, 200 80"
              fill="none"
              stroke="rgba(0, 0, 0, 0.1)"
              strokeWidth="1"
            />
          </svg>
        </div>
      </header>

      <main className={styles.main}>
        <Gallery
          title="Commercial Metal Solutions"
          images={commercialImages}
          description="Our commercial metal solutions encompass a wide range of specialized applications, from intricate interior stairwell railings to robust multi-family housing aluminum fencing. We understand that commercial projects demand precision, durability, and aesthetic sophistication. Our fully welded designs ensure maximum strength, while our premium powder coating provides long-lasting protection and visual appeal. Whether you're designing modern office spaces, industrial facilities, or residential complexes, our custom metal solutions are engineered to meet the most demanding specifications."
          galleryType="commercial"
        />

        <div className={styles.ctaSection}>
          <h2>Ready to Elevate Your Commercial Space?</h2>
          <button
            onClick={() => navigate('/contact')}
            className={styles.ctaButton}
            aria-label="Contact us for a commercial consultation"
          >
            Request Consultation
          </button>
        </div>
      </main>

      {selectedImage && createPortal(
        <div
          className={styles.modal}
          onClick={() => setSelectedImage(null)}
        >
          <div
            className={styles.modalContent}
            onClick={e => e.stopPropagation()}
          >
            <img
              src={selectedImage.src}
              alt={selectedImage.alt}
              className={styles.modalImage}
            />
            <p className={styles.modalDescription}>
              {selectedImage.description}
            </p>
            <button
              className={`${styles.navButton} ${styles.prevButton}`}
              onClick={(e) => {
                e.stopPropagation();
                handleImageNavigation('prev');
              }}
            >
              ‹
            </button>
            <button
              className={`${styles.navButton} ${styles.nextButton}`}
              onClick={(e) => {
                e.stopPropagation();
                handleImageNavigation('next');
              }}
            >
              ›
            </button>
            <button
              className={styles.closeButton}
              onClick={() => setSelectedImage(null)}
            >
              ×
            </button>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

export default Commercial;