import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './About.module.css';
import aboutImage from '../assets/images/about.webp';

function About() {
  return (
    <div className={styles.aboutContainer}>
      <Helmet>
        <title>About Us - ABC Railing</title>
        <meta
          name="description"
          content="Learn more about ABC Railing, a Canadian fabrication company specializing in aluminum, iron & glass railings, staircases, fencing, and gates."
        />
      </Helmet>
      <img src={aboutImage} alt="ABC Railing" className={styles.aboutImage} />
      <h1 className={styles.title}>About Us</h1>
      <div className={styles.content}>

        {/* Introduction Section */}
        <section aria-label="Introduction">
          <p className={styles.description}>
            Formerly South Side Manufacturing Ltd., as of January 30, 2025, we are proudly operating as ABC Railing.
          </p>
          <p className={styles.sectionText}>
            The transition to the ABC Railing brand reflects our dedication to Edmonton’s building community and better meeting the unique needs of our Architectural, Builder, and Commercial clients.
          </p>
        </section>

        {/* Products Section */}
        <section aria-label="Products">
          <h2 className={styles.sectionTitle}>Our Products</h2>
          <div className={styles.seriesContent}>
            <ul>
              <li>
                <strong>Architectural Series</strong>: High-end railings and architectural metal features, locally hand-crafted in Edmonton from premium aluminum and iron alloys. Perfect for sophisticated exterior and interior designs.
              </li>
              <li>
                <strong>Builder Series (BGRS)</strong>: Cost-effective, quick-delivery solutions crafted from durable aluminum alloys in textured black and gloss white finishes—designed for the fast-paced needs of builders.
              </li>
              <li>
                <strong>Commercial Series</strong>: Engineered to meet stringent building codes while delivering durability and style. This series provides reliable, compliant solutions for a wide range of commercial projects.
              </li>
            </ul>
          </div>
          <p className={styles.sectionText}>
            By listening to our customers, we’ve refined how we serve you — delivering high-quality products with faster lead times.
          </p>
        </section>

        {/* Sandhills Group Section */}
        <section aria-label="Sandhills Group">
          <h2 className={styles.sectionTitle}>The Sandhills Group</h2>
          <p className={styles.sectionText}>
            We are proud to be a part of the Sandhills Group of Companies, a comprehensive network of manufacturing excellence. Besides ABC Railing, our group includes Woodchucks Manufacturing, Sandhills Manufacturing, and Ply-Lam. Together, we offer an integrated solution for your construction needs, ranging from construction-grade stairs to finished-grade stairs and fully-welded aesthetic metal railings and components.
          </p>
          <p className={styles.sectionText}>
            Our commitment to quality is deeply rooted in our Canadian heritage. All our products are meticulously designed in Canada, with the majority manufactured in-house, ensuring the highest standards of craftsmanship and innovation.
          </p>
        </section>

        {/* Powder Coating Innovation Section */}
        <section aria-label="Powder Coating Innovation">
          <h2 className={styles.sectionTitle}>Powering Our Innovation: In-House Powder Coating</h2>
          <p className={styles.sectionText}>
            When we first joined the Sandhills Group, we embarked on a transformative journey. We reimagined our operational approach, introducing cutting-edge technologies and streamlining our processes to meet the demands of the modern manufacturing landscape.
          </p>
          <p className={styles.sectionText}>
            One persistent challenge was our powder coating workflow. Commercial powder coaters' extensive wait lists were causing significant delays, sometimes extending project timelines by months. Our solution? In 2022, we established our first in-house powder coating facility—a game-changing move that revolutionized our production capabilities.
          </p>
          <p className={styles.sectionText}>
            This strategic investment allowed us to take complete control of our finishing processes. We gained the flexibility to schedule work on our terms, implemented rigorous quality control measures, and dramatically improved our logistics. The result? A seamless, efficient workflow that delivers exceptional value to our customers.
          </p>
        </section>

        {/* Our BGRS Journey Section */}
        <section aria-label="Our BGRS Journey">
          <h2 className={styles.sectionTitle}>Our BGRS Journey</h2>
          <p className={styles.sectionText}>
            At ABC Railing, we pride ourselves on pushing the boundaries of design and engineering. Initially, our premium products faced two challenges: cost and timeline. Though highly sought after for their precision and elegance, the detailed crafting process resulted in higher prices and extended lead times.
            <br /><br />
            After months of development, we introduced a revolutionary system that maintains premium aesthetics while being assembled on-site, reducing installation lead times. This innovation not only makes our railings more accessible but also ensures they comply with the National Building Code, providing safety and durability with a luxurious feel.
          </p>
        </section>

        {/* Our Commitment Section */}
        <section aria-label="Commitment">
          <h2 className={styles.sectionTitle}>Our Commitment</h2>
          <div className={styles.commitmentQuote}>
            Right the first time,<br />
            on time,<br />
            <span>every time.</span>
          </div>
          <p className={styles.sectionText}>
            At ABC Railing, we are committed to the principles of "Right the first time, on time, every time." Our dedicated team ensures that every product meets the highest standards and is delivered on schedule, embodying our promise of excellence and reliability.
          </p>
        </section>

        {/* Closing Section */}
        <section aria-label="Closing">
          <p className={styles.sectionText}>
            Proudly serving Edmonton and beyond, we invite you to explore how ABC Railing can support your next project.
          </p>
          <p className={styles.sectionText}>
            Contact us at{' '}
            <a href="tel:+17804390801" tabIndex="0">
              +1 780 439 0801
            </a>{' '}
            or{' '}
            <a href="mailto:hello@example.com" tabIndex="0">
              hello@abcrailing.com
            </a>{' '}
            to learn more!
          </p>
          <p className={styles.sectionText}>
            ABC Railing — Your Partner in Precision and Quality
          </p>
        </section>

      </div>
    </div>
  );
}

export default About;